import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPassword from "pages/Authentication/ForgetPassword"
import Compareotp from "pages/Authentication/Compareotp"
import Resetpsw from "pages/Authentication/Resetpsw"

//DEPAETMENTS
import Departments from "pages/Staff/Departments"
import RolesPremissions from "pages/Staff/RolesPremissions"
import Staff from "pages/Staff/Staff"
import AddRoles from "pages/Staff/AddRoles"
import EditRoles from "pages/Staff/EditRoles"

//SETTINGS
import Terms from "pages/Settings/Terms"
import Faqs from "pages/Settings/Faqs"
import PrivicyPolicy from "pages/Settings/PrivicyPolicy"
import RefundPolicy from "pages/Settings/RefundPolicy"
import Charges from "pages/Settings/Charges"
import Testing from "pages/Settings/Testing"
import ContactUs from "pages/Settings/ContactUs"

import Coupones from "pages/Promo/Coupones"
import Notifications from "pages/Notifications/Notifications"
import Users from "pages/Users/Users"
import Banners from "pages/Banners/Banners"
import SubBanners from "pages/Banners/SubBanners"

import AddVendors from "pages/Vendors/AddVendors"
import EditVendor from "pages/Vendors/EditVendor"
import Vendors from "pages/Vendors/Vendors"
import Booking from "pages/Booking/Booking"
import Payments from "pages/Payments/Payments"

import ServiceCategory from "pages/Services/ServiceCategory"
import Services from "pages/Services/Services"

import ViewUser from "pages/Users/ViewUser"

import ViewVendor from "pages/Vendors/ViewVendor"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import BookingView from "pages/Booking/BookingView"
import Pendingbookings from "pages/Booking/Pendingbookings"
import CompletedBookings from "pages/Booking/CompletedBookings"
import ReBookings from "pages/Booking/ReBookings"
import CanclledBookings from "pages/Booking/CanclledBookings"

import UserSupport from "pages/Support/UserSupport"
import VendorSupport from "pages/Support/VendorSupport"

import UserPlan from "pages/Plans/UserPlan"
import VendorPlan from "pages/Plans/VendorPlan"

import AllVendorsDashboard from "pages/Vendors/AllVendorsDashboard"
import SubService from "pages/Services/SubService"
import VendorsBookingView from "pages/Vendors/VendorsBookingView"

import Wallet from "pages/Payments/Wallet"
import UserWallet from "pages/Payments/UserWallet"

import UserPrivacyPolicy from "pages/Settings/UserPrivacyPolicy"
import UserRefundPolicy from "pages/Settings/UserRefundPolicy"
import UserTerms from "pages/Settings/UserTerms"
import UserFaqs from "pages/Settings/UserFaqs"

import ViewUserSubsriptionList from "pages/Plans/ViewUserSubsriptionList"
import ViewVendorSubsriptionList from "pages/Plans/ViewVendorSubsriptionList"
import Category from "pages/Services/Category"

//REPORTS
import VendorWalletReport from "pages/Reports/VendorWalletReport"
import VendorSubscription from "pages/Reports/VendorSubscription"
import VendorWiseReport from "pages/Reports/VendorWiseReport"
import UserWiseReport from "pages/Reports/UserWiseReport"
import UserSubscription from "pages/Reports/UserSubscription"
import UserWalletReport from "pages/Reports/UserWalletReport"
import BookingReport from "pages/Reports/BookingReport"
import PaymentReport from "pages/Reports/PaymentReport"

import RecommendedBanners from "pages/Banners/RecommendedBanners"

import Campaigns from "pages/Vendors/Campaigns"
import ViewCampaign from "pages/Vendors/ViewCampaign"

import PendingVendorsList from "pages/Vendors/PendingVendorsList"

import ViewPendingVendors from "pages/Vendors/ViewPendingVendors"

import VendorPlanWiseList from "pages/Vendors/VendorPlanWiseList"

import InActiveVendor from "pages/Vendors/InActiveVendor"

import InActiveUsers from "pages/Users/InActiveUsers"

import DeleteAccount from "pages/DeleteAccount"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/profile", component: UserProfile },

  { path: "/InActiveVendor", component: InActiveVendor },

  { path: "/RecommendedBanners", component: RecommendedBanners },

  { path: "/ViewCampaign", component: ViewCampaign },

  { path: "/PendingVendorsList", component: PendingVendorsList },

  { path: "/VendorPlanWiseList", component: VendorPlanWiseList },

  { path: "/Campaigns", component: Campaigns },

  { path: "/ViewPendingVendors", component: ViewPendingVendors },

  { path: "/InActiveUsers", component: InActiveUsers },

  //Suport
  { path: "/UserSupport", component: UserSupport },
  { path: "/VendorSupport", component: VendorSupport },

  //Bookings
  { path: "/Booking", component: Booking },
  { path: "/BookingView", component: BookingView },
  { path: "/Pendingbookings", component: Pendingbookings },
  { path: "/CompletedBookings", component: CompletedBookings },
  { path: "/ReBookings", component: ReBookings },
  { path: "/CanclledBookings", component: CanclledBookings },

  //Staff
  { path: "/Departments", component: Departments },
  { path: "/RolesPremissions", component: RolesPremissions },
  { path: "/AddRoles", component: AddRoles },
  { path: "/EditRoles", component: EditRoles },
  { path: "/Staff", component: Staff },

  //Service
  { path: "/ServiceCategory", component: ServiceCategory },
  { path: "/SubService", component: SubService },
  { path: "/Services", component: Services },
  { path: "/Category", component: Category },

  //Vendors
  { path: "/ViewVendorSubsriptionList", component: ViewVendorSubsriptionList },
  { path: "/ViewUserSubsriptionList", component: ViewUserSubsriptionList },
  { path: "/AllVendorsDashboard", component: AllVendorsDashboard },
  { path: "/VendorsBookingView", component: VendorsBookingView },
  { path: "/ViewVendor", component: ViewVendor },
  { path: "/AddVendors", component: AddVendors },
  { path: "/EditVendor", component: EditVendor },
  { path: "/VendorPlan", component: VendorPlan },
  { path: "/Vendors", component: Vendors },
  { path: "/Wallet", component: Wallet },

  //Reports
  { path: "/VendorWalletReport", component: VendorWalletReport },
  { path: "/VendorSubscription", component: VendorSubscription },
  { path: "/VendorWiseReport", component: VendorWiseReport },
  { path: "/UserWiseReport", component: UserWiseReport },
  { path: "/UserSubscription", component: UserSubscription },
  { path: "/UserWalletReport", component: UserWalletReport },
  { path: "/BookingReport", component: BookingReport },
  { path: "/PaymentReport", component: PaymentReport },

  //Payments
  { path: "/Payments", component: Payments },

  //Coupones
  { path: "/Coupones", component: Coupones },

  //Banners
  { path: "/SubBanners", component: SubBanners },
  { path: "/Banners", component: Banners },

  //Users
  { path: "/UserWallet", component: UserWallet },
  { path: "/UserPlan", component: UserPlan },
  { path: "/ViewUser", component: ViewUser },
  { path: "/Users", component: Users },

  //Notifications
  { path: "/Notifications", component: Notifications },

  //Settings
  { path: "/UserRefundPolicy", component: UserRefundPolicy },
  { path: "/UserPrivacyPolicy", component: UserPrivacyPolicy },
  { path: "/PrivicyPolicy", component: PrivicyPolicy },
  { path: "/RefundPolicy", component: RefundPolicy },
  { path: "/Termsconditions", component: Terms },
  { path: "/ContactUs", component: ContactUs },
  { path: "/UserTerms", component: UserTerms },
  { path: "/UserFaqs", component: UserFaqs },
  { path: "/Charges", component: Charges },
  { path: "/test", component: Testing },
  { path: "/Faqs", component: Faqs },

  // this route should be at the end of all other routesside
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/ForgetPassword", component: ForgetPassword },
  { path: "/Compareotp", component: Compareotp },
  { path: "/Resetpsw", component: Resetpsw },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/deleteaccount", component: DeleteAccount },
]

export { publicRoutes, authProtectedRoutes }
