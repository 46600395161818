import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"

const Banner = () => {
  const [modal_small, setmodal_small] = useState(false)

  const [banner, setbanner] = useState([])

  const [form1, setform1] = useState([])

  const [form2, setform2] = useState([])

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange2 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  useEffect(() => {
    GetAllWalletRequest()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const EditVendorWallet = () => {
    var token = datas
    var formid = form1._id
    const dataArray = {
      status: form2.status,
      transactionId: form2.transactionId,
    }
    axios
      .put(URLS.UpdateVendorWalletRequest + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllWalletRequest()
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditVendorWallet()
  }

  const GetAllWalletRequest = () => {
    var token = datas
    axios
      .post(
        URLS.VendorWalletRequest,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.data)
      })
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        URLS.VendorWalletRequestSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.data)
      })
  }
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="GoCut" breadcrumbItem="Vendor Wallet Request" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                      <Table className="table table-bordered mb-4 mt-5">
                        <thead>
                          <tr className="text-center">
                            <th>S.No</th>
                            <th>Request Date/Time</th>
                            <th>Salon Name</th>
                            <th>Vendor Id</th>
                            <th>Vendor Name</th>
                            <th>Vendor Phone</th>
                            <th>Wallet Balance</th>
                            <th>Transaction Amount</th>
                            <th>Type</th>
                            <th>Transaction Id</th>
                            <th>Transtation Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key} className="text-center">
                              <td>{(pageNumber - 1) * 5 + key + 6}</td>
                              <td>
                                {data.date} / {data.time}{" "}
                              </td>
                              <td>{data.salonName}</td>
                              <td>{data.userUniqueId}</td>
                              <td>{data.vendorName}</td>
                              <td>{data.vendorPhone}</td>
                              <td>{data.vendorWallet}</td>
                              <td>{data.amount}</td>
                              <td>{data.type}</td>
                              <td>
                                {data.transactionId == "" || null ? (
                                  <>-</>
                                ) : (
                                  <>{data.transactionId}</>
                                )}
                              </td>
                              <td>{data.status}</td>
                              <td>
                                {data.status == "Completed" ||
                                data.status == "Rejected" ? (
                                  <>-</>
                                ) : (
                                  <>
                                    <Button
                                      onClick={() => {
                                        getpopup(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      color="success"
                                    >
                                      <div className="d-flex">
                                        <i className="bx bx-edit px-1"></i>
                                        <small> Update </small>
                                      </div>
                                    </Button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Update Vendor Wallet Request
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Amount</Label>
                <Input
                  type="number"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter User Amount"
                  required
                  value={form1.amount}
                  name="amount"
                  disabled
                />
              </div>
              <div className="mb-3">
                <Label> Payment Status </Label>{" "}
                <span className="text-danger">*</span>
                <select
                  name="status"
                  value={form2.status}
                  required
                  className="form-select"
                  onChange={e => {
                    handleChange2(e)
                  }}
                >
                  <option value="">Select</option>
                  <option value="Completed">Paid </option>
                </select>{" "}
              </div>

              {form2.status == "Completed" ? (
                <>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Transaction Id :<span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Transaction Id"
                      required
                      value={form2.transactionId}
                      name="transactionId"
                      onChange={e => {
                        handleChange2(e)
                      }}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Banner
