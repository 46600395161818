import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "../../Url"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const v = e => {
    e.preventDefault()
    AddSalon()
  }

  const [files, setFiles] = useState([])

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const [address, setaddress] = useState("")
  const [district, setDistrict] = useState("")

  const [coordinateds, setcoordinateds] = useState({
    lat: "",
    lng: "",
    address: "",
  })

  const handleSelects = async value => {
    setaddress(value)
    try {
      const results = await geocodeByAddress(value)
      const latLng = await getLatLng(results[0])
      setcoordinateds(latLng)

      const addressComponents = results[0].address_components

      const districtComponent = addressComponents.find(component =>
        component.types.includes("administrative_area_level_3")
      )

      setDistrict(districtComponent ? districtComponent.long_name : "")
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const handleChange = setter => e => {
    setter(e.target.value)
  }

  const history = useHistory()
  const AddSalon = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("saloonName", form.saloonName)
    dataArray.append("salonMobileNumber", form.salonMobileNumber)
    dataArray.append("name", form.name)
    dataArray.append("mobileNumber", form.mobileNumber)
    dataArray.append("email", form.email)
    dataArray.append("designation", form.designation)
    dataArray.append("address", address)
    dataArray.append("city", district)
    dataArray.append("latitude", coordinateds.lat)
    dataArray.append("longitude", coordinateds.lng)
    dataArray.append("bankName", form.bankName)
    dataArray.append("accountHolderName", form.accountHolderName)
    dataArray.append("accountNumber", form.accountNumber)
    dataArray.append("branch", form.branch)
    dataArray.append("IfscCode", form.IfscCode)
    dataArray.append("seats", form.seats)
    dataArray.append("vendorId", form._id)

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("logo", selectedFiles[i])
    }

    for (let i = 0; i < files.length; i++) {
      dataArray.append("documents", files[i])
    }

    axios
      .put(URLS.UpdateVendorIdLists, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Vendors")
            sessionStorage.setItem(
              "tost",
              "Vendor has been Update Successfully"
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  useEffect(() => {
    GetOneOrder()
  }, [])

  const OrderId = sessionStorage.getItem("UserId")

  const GetOneOrder = () => {
    const data = {
      vendorId: OrderId,
    }

    var token = datas
    axios
      .post(URLS.GetVendorIdLists, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.sateesh)
        const address = res.data.sateesh
        setaddress(address.address)
        setDistrict(address.city)
        const c = { ...coordinateds }
        c["lat"] = address.maplocation.coordinates[0]
        c["lng"] = address.maplocation.coordinates[1]
        setcoordinateds(c)
      })
  }

  const [Category, setCategory] = useState([])

  useEffect(() => {
    getCategory()
  }, [])

  const getCategory = () => {
    var token = datas
    axios
      .post(
        URLS.GetCategory,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setCategory(res.data.data)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="GoCut" breadcrumbItem="Edit Vendor" />
          <Form
            onSubmit={e => {
              v(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <h5
                      className="mb-4 text-primary"
                      style={{ fontWeight: "bold" }}
                    >
                      Store Information
                    </h5>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Salon Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Salon  Name"
                        required
                        value={form.saloonName}
                        name="saloonName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Salon Contact Number
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Salon Contact Number"
                        required
                        minLength="10"
                        maxLength="10"
                        pattern="[0-9]+"
                        value={form.salonMobileNumber}
                        name="salonMobileNumber"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label> Seating </Label>
                      <span className="text-danger">*</span>
                      <select
                        name="seats"
                        onChange={e => {
                          handlechange(e)
                        }}
                        value={form.seats}
                        required
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="1">1 </option>
                        <option value="2">2 </option>
                        <option value="3">3 </option>
                        <option value="4">4 </option>
                        <option value="5">5 </option>
                        <option value="6">6 </option>
                        <option value="7">7 </option>
                        <option value="8">8 </option>
                        <option value="9">9 </option>
                        <option value="10">10 </option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Documents <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter image"
                        name="image"
                        onChange={changeHandler}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="text-center m-4 pt-4">
                      <h5 style={{ fontWeight: "bold" }}>Profile</h5>
                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <div className="mb-3">
                      <Label> Category </Label>
                      <span className="text-danger">*</span>
                      <select
                        name="categoryId"
                        onChange={e => {
                          handlechange(e)
                        }}
                        value={form.categoryId}
                        required
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {Category.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Search location <span className="text-danger">*</span>
                      </Label>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setaddress}
                        onSelect={handleSelects}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div
                            key={suggestions.description}
                            className="form-group "
                          >
                            <input
                              {...getInputProps({
                                placeholder: "Search location ...",
                                className: "location-search-input form-control",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item"
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    }

                                return (
                                  // <></>
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    key={suggestion.placeId}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <label>Latitude</label>
                      <input
                        id="name"
                        type="number"
                        className="form-control"
                        placeholder="Enter latitude"
                        name="latittude"
                        value={coordinateds.lat}
                        disabled="disabled"
                        onChange={e => {
                          handlechange(e)
                        }}
                        required
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <label>Longitude</label>
                      <input
                        id="name"
                        type="number"
                        className="form-control"
                        placeholder="Enter longitude"
                        name="longitude"
                        value={coordinateds.lng}
                        onChange={e => {
                          handlechange(e)
                        }}
                        disabled="disabled"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        City
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter City"
                        required
                        value={district}
                        name="district"
                        onChange={handleChange(setDistrict)}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Address <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="1"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Address"
                        required
                        value={address}
                        name="address"
                        onChange={handleChange(setaddress)}
                      />
                    </div>
                  </Col>
                  <h5
                    className="mb-2 text-primary mt-2"
                    style={{ fontWeight: "bold" }}
                  >
                    Vendor Information
                  </h5>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Name<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Name"
                        required
                        value={form.name}
                        name="name"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Mobile<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Mobile"
                        required
                        value={form.mobileNumber}
                        name="mobileNumber"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Email
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Email"
                        required
                        value={form.email}
                        name="email"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Designation<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Designation"
                        required
                        value={form.designation}
                        name="designation"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h5
                      className="mb-4 text-primary"
                      style={{ fontWeight: "bold" }}
                    >
                      Bank Information
                    </h5>
                    <Row className="mt-3">
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Bank Name<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Bank Name"
                            required
                            value={form.bankName}
                            name="bankName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Account Holder Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Account Holder Name"
                            required
                            value={form.accountHolderName}
                            name="accountHolderName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Account Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Account Number"
                            required
                            type="text"
                            minLength="12"
                            maxLength="16"
                            pattern="[0-9]+"
                            value={form.accountNumber}
                            name="accountNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Branch<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Branch"
                            required
                            value={form.branch}
                            name="branch"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            IFSC Code
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter IFSC Code"
                            required
                            value={form.IfscCode}
                            pattern="^[A-Z]{4}0\d{6}$"
                            name="IfscCode"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12}>
                <div className=" mb-2" style={{ float: "right" }}>
                  <button
                    type="submit"
                    style={{ width: "120px" }}
                    className="btn btn-info m-1"
                  >
                    Submit <i className="fas fa-check-circle"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default AddVendors
