import React, { useEffect, useState } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { CSVLink } from "react-csv"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { URLS } from "../../Url"

function PaymentReport() {
  const [Actin, setActin] = useState([])
  useEffect(() => {
    ActinReport()
  }, [])

  const [userInCsv, setuserInCsv] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const ActinReport = () => {
    var token = datas
    const data = { fromDate: "", toDate: "", status: "All" }

    axios
      .post(URLS.GetAllBookingTestReports, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.data)
        setuserInCsv(res.data.ExcelData)
      })
  }
  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    fromDate: "",
    toDate: "",
    status: "All",
  })

  const getfilter = e => {
    e.preventDefault()
    GetActinFiliter()
  }

  const GetActinFiliter = () => {
    var token = datas
    const data = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      status: filters.status,
    }
    axios
      .post(URLS.GetAllBookingTestReports, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.data)
        setuserInCsv(res.data.ExcelData)
        hidefilter()
        setfilters("")
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const csvReport = {
    filename: "Booking Report",
    data: userInCsv,
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A2"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "S.No",
        "Salon Name",
        "User Id",
        "Booking Id",
        "User Name",
        "Contact No",
        "Services Details ",
        "Date",
        "Status",
      ],
    ]

    const data = Actin.map((elt, i) => [
      i + 1,
      elt.actinBookingCode,
      elt.hcuUserCode,
      elt.hcuBookingCode,
      elt.bookingDate,
      elt.bookingStatus,
      elt.subTotal,
      elt.refundAmount,
      elt.paymentStatus,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Booking_Report.pdf")
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="GoCut" breadcrumbItem="Payment Wise Report" />
            {filter ? (
              <>
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        getfilter(e)
                      }}
                    >
                      <Row>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              From Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="fromDate"
                              value={filters.fromDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              To Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="toDate"
                              value={filters.toDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label>Status</Label>
                            <span className="text-danger">*</span>
                            <select
                              value={filters.status}
                              required
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="status"
                              className="form-select"
                            >
                              <option value="All">Select All</option>
                              <option value="PendingBookings">
                                Pending Bookings
                              </option>
                              <option value="CompletedBookings">
                                Completed Bookings
                              </option>
                              <option value="CancelledBooking">
                                Cancelled Booking
                              </option>
                              <option value="Re-ScheduleBookingList">
                                Re-Schedule Bookings List
                              </option>
                            </select>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mt-4">
                            <Button type="submit" className="m-1" color="info">
                              <i className="fas fa-check-circle"></i> search
                            </Button>
                            <Button
                              onClick={hidefilter}
                              className="m-1"
                              color="danger"
                            >
                              <i className="fas fa-times-circle"></i> Cancel
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </>
            ) : (
              ""
            )}

            <Card>
              <CardBody>
                <div>
                  <div className="table-responsive">
                    <div className="col-sm-12">
                      <div style={{ float: "right" }}>
                        <Row>
                          <Col>
                            <div style={{ float: "right" }}>
                              <CSVLink {...csvReport}>
                                <button
                                  className="btn btn-success me-2"
                                  type="submit"
                                >
                                  <i className="fas fa-file-excel"></i> Excel
                                </button>
                              </CSVLink>
                              <Button
                                type="button"
                                className="btn btn-danger "
                                onClick={exportPDF}
                              >
                                <i className="fas fa-file-pdf"></i> Pdf
                              </Button>
                              <Button
                                className="m-1"
                                onClick={() => {
                                  setfilter(!filter)
                                }}
                                color="info"
                              >
                                <i className="fas fa-filter"></i> Filter
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="mt-3">
                      <Table
                        id="empTable"
                        className="table table-bordered mb-3 mt-5"
                      >
                        <thead className="text-center">
                          <tr className="text-center">
                            <th>S.No</th>
                            <th>Salon Name </th>
                            <th>User Id</th>
                            <th>Booking Id</th>
                            <th>User Name</th>
                            <th>Contact No</th>
                            <th>Services Details</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          <tr className="text-center">
                            <td>1</td>
                            <td>Inspirations Salon</td>
                            <td>Ins101</td>
                            <td>Gocut101</td>
                            <td>sameer</td>
                            <td>9988774455</td>
                            <td>Hair Cut </td>
                            <td>22-22-2023</td>
                            <td>
                              <span className="badge bg-danger">9:20 Am</span>
                            </td>
                            <td>Pending</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className="mt-3" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default PaymentReport
