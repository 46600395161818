import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Label } from "reactstrap"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { URLS } from "../../Url"
import profileImg from "../../assets/images/profile-img.png"
import Select from "react-select"
import ReactApexChart from "react-apexcharts"

const Dashboard = () => {
  const [form, setform] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([])

  const [test, settest] = useState([])

  const [Calendar, setCalendar] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const reports = [
    {
      title: "All Bookings ",
      iconClass: "bx-copy-alt",
      description: form.allBookings,
    },
    {
      title: "Pending Bookings",
      iconClass: "bx-purchase-tag-alt",
      description: form.pendingBookingsCount,
    },
    {
      title: "Completed Bookings ",
      iconClass: "bx-archive-in",
      description: form.completedBookingsCount,
    },
    {
      title: "Cancelled Bookings ",
      iconClass: "bx-archive-in",
      description: form.cancelledBookingscount,
    },
  ]

  const reports2s = [
    {
      title: "Users",
      iconClass: "bx-copy-alt",
      description: form.totalUsers,
    },
    {
      title: "Pending Amount",
      iconClass: "bx-archive-in",
      description: form.pendingAmount,
    },
    {
      title: "Settled Amount",
      iconClass: "bx-copy-alt",
      description: form.settledAmount,
    },
    {
      title: "Total Amount",
      iconClass: "bx-purchase-tag-alt",
      description: form.OverallAmount,
    },
  ]

  const reports3s = [
    {
      title: "Current Plan",
      iconClass: "bx-copy-alt",
      description: form.planName,
    },

    {
      title: "Expire Date",
      iconClass: "bx-purchase-tag-alt",
      description: form.endDate,
    },
    {
      title: "Recharge Amount",
      iconClass: "bx-archive-in",
      description: form.rechargeAmount?.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue.amount),
        0
      ),
    },
    {
      title: "Wallet Amount",
      iconClass: "bx-copy-alt",
      description: form.wallet,
    },
  ]

  const eventRender = info => {
    const now = new Date()
    if (info.event.start < now) {
      info.el.classList.add("past-event")
    }
  }

  const HealthScheme = selectedOptions => {
    var token = datas
    axios
      .post(
        URLS.GetAllVendorDashboard,
        { vendorId: selectedOptions.value },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data)
        setCalendar(res.data.bookingDetails)
      })
    setSelectedOptions(selectedOptions)
  }

  const hospital = test.map(response => ({
    value: response._id,
    label: response.name,
  }))

  useEffect(() => {
    GetVendors()
  }, [])

  const GetVendors = () => {
    var token = datas
    axios
      .post(
        URLS.GetVendorList,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        settest(res.data.data)

        const js = res.data.data[0]._id

        axios
          .post(
            URLS.GetAllVendorDashboard,
            { vendorId: js },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(res => {
            setform(res.data)
          })
      })
  }

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "34%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },

    colors: ["#63183F", "#F77E7E"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: " (data)",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  const series = [
    {
      name: "Customers",
      data: form.monthlyUsers,
    },
    {
      name: "Amount",
      data: form.MonthlyAmount,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"GoCut"}
            breadcrumbItem={"All Vendors Dashboard"}
          />
          <Row>
            <Col md="6"> </Col>
            <Col md="6">
              <div className="mb-3" style={{ float: "right", width: "300px" }}>
                <Label> Select Vendor </Label>{" "}
                <span className="text-danger">*</span>
                <Select
                  options={hospital}
                  placeholder="Select Vendor"
                  value={selectedOptions}
                  onChange={HealthScheme}
                  isSearchable={true}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Welcome Back </h5>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="5">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={URLS.Base + form.salonLogo}
                          alt=""
                          style={{ height: "70px" }}
                          className="img-thumbnail rounded-circle"
                        />
                      </div>
                      <h5>{form.salonName} </h5>
                    </Col>
                    <Col sm="7">
                      <div className="pt-4">
                        <Row>
                          <Col xs="12">
                            <h5 className="font-size-15">Phone :</h5>
                            <p className="text-muted mb-0">
                              {form.salonMobileNumber}
                            </p>
                          </Col>
                          <Col xs="12" className="mt-3">
                            <h5 className="font-size-15">Address :</h5>
                            <p className="text-muted mb-0">
                              {form.salonAddress}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Row>
                {reports.map((report, key) => (
                  <Col md="6" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md={12}>
              <Row>
                {reports2s.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md={12}>
              <Row>
                {reports3s.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                  />
                </CardBody>
              </Card>{" "}
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <div>
                    <FullCalendar
                      plugins={[dayGridPlugin]}
                      initialView="dayGridMonth"
                      events={Calendar}
                      eventRender={eventRender}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
