import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Input, Button, Table, Modal,Label  } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"

function Ventures() {
  const [Actin, setActin] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetUsers()
    datass()
  }, [])

  const GetUsers = () => {
    var token = datas
    axios
      .post(
        URLS.GetUser,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.userdetails)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const OrderId1 = data => {
    sessionStorage.setItem("UserIds", data._id)
    history.push("/ViewUser")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  // const manageDelete = data => {
  //   const confirmBox = window.confirm("Do you really want to InActive?")
  //   if (confirmBox === true) {
  //     Delete(data)
  //   }
  // }
  const [form5, setform5] = useState([])
  const handleChange = e => {
    let myUser = { ...form5 }
    myUser[e.target.name] = e.target.value
    setform5(myUser)
  }

  const handleblockUsers = data => {
    toggleModal()
    setform5(data)
  }

  const handleSubmit1 = (e) => {
    e.preventDefault()
    var token = datas
    var remid = form5._id

    const gs = {
      status: "inactive",
      blockedReason: form5.blockedReason,
    }

    axios
      .put(URLS.GetUserID + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetUsers()
            toggleModal()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you really want to Active?")
    if (confirmBox === true) {
      Delete1(data)
    }
  }

  const Delete1 = data => {
    var token = datas
    var remid = data._id

    const gs = {
      status: "active",
    }

    axios
      .put(URLS.GetUserID + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetUsers()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetUserSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.userdetails)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="GoCut" breadcrumbItem="User list" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          value={Searchs.search}
                          onChange={Search}
                          className="form-control"
                          placeholder="Search.."
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>User Id</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Wallet Balance </th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td className="text-primary" type="button"  onClick={() => {
                                  OrderId1(data);
                                }}>{data.userUniqueId}</td>
                            <td>{data.name}</td>
                            <td>{data.mobileNumber}</td>
                            <td>{data.email}</td>
                            <td>{data.wallet}</td>
                            <td>
                              {data.status == "active" ? (
                                <>
                                  <span className="badge bg-success ">
                                    {data.status}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="badge bg-danger ">
                                    {data.status}
                                  </span>
                                </>
                              )}
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  OrderId1(data)
                                }}
                                size="sm"
                                className="m-1"
                                color="info"
                              >
                                <div className="d-flex">
                                  <i className="fas fa-eye px-1"></i>
                                  <small> View </small>
                                </div>
                              </Button>
                              {data.status == "active" ? (
                                <Button
                                  onClick={() => {
                                    handleblockUsers(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  color="danger"
                                >
                                  <div className="d-flex">
                                    <i className="fas fa-user-lock px-1"></i>
                                    <small> InActive </small>
                                  </div>
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    manageDelete1(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  color="success"
                                >
                                  <div className="d-flex">
                                    <i className="fas fa-user-check px-1"></i>
                                    <small>Active</small>
                                  </div>
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>{" "}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />

          <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          role="dialog"
          // size="sm"
          autoFocus={true}
          centered
          data-toggle="modal"
        >
          {/* <div>
            <ModalHeader
              toggle={() => {
                setmodal(!modal)
              }}
            >
            </ModalHeader>
          </div> */}
          <div className="modal-body">
            <form
              onSubmit={e => {
                handleSubmit1(e)
              }}
              className="text-center"
            >
              <i
                className="bx bx-x-circle text-danger"
                style={{ fontSize: "100px" }}
              />

              {/* <h5 className="text-center">
                {form5.userName}
              </h5> */}
              <h5 className="text-center">Do you want to block this user</h5>

              <div className="mt-3">
                <Label>
                  Reason <span className="text-danger">*</span>
                </Label>
                <textarea
                  required
                  className="form-control"
                  type="text"
                  name="blockedReason"
                  onChange={e => handleChange(e)}
                  value={form5.blockedReason}
                  placeholder="Enter Reason"
                />
              </div>

              <div className="text-center mt-3">
                <Button
                  className="m-2"
                  style={{ width: "120px" }}
                  type="submit"
                  color="success"
                  outline
                >
                  <i className="bx bx-check-circle" /> Yes
                </Button>
                <Button
                  className="m-2"
                  onClick={toggleModal}
                  style={{ width: "120px" }}
                  type="button"
                  color="danger"
                  outline
                >
                  <i className="bx bx-x-circle" /> Cancel
                </Button>
              </div>
            </form>
          </div>
        </Modal>

        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
