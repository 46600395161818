import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button, Table, Modal } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import logo from "../../assets/images/logos.png"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"

function TestsView() {
  const history = useHistory()

  const [Salon, setSalon] = useState([])

  const [Payments, setPayments] = useState([])

  const [Booking, setBooking] = useState([])

  const [VendorEmail, setVendorEmail] = useState([])

  const [User, setUser] = useState([])

  useEffect(() => {
    GetOneOrder()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const OrderId = sessionStorage.getItem("BookingId")

  const GetOneOrder = () => {
    const data = {
      orderId: OrderId,
    }
    var token = datas
    axios
      .post(URLS.GetOneBookingsId, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setSalon(res?.data?.saloons[0])
        setPayments(res?.data?.payments[0])
        setBooking(res?.data?.orders)
        setVendorEmail(res?.data?.orders[0])
        setUser(res?.data?.users[0])
      })
  }

  const [info, setinfo] = useState(false)
  const [Information, setInformation] = useState([])

  function infos() {
    setinfo(!info)
  }

  const getinfo = data => {
    setInformation(data?.order?.invoice)
    infos()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="GoCut" breadcrumbItem="Booking View" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h5 className="text-primary">Salon Details :-</h5>
                  <Col md={12}>
                    <div className="text-center">
                      <div className="avatar-md1 profile-user-wid  mt-1">
                        <span className="ml-5">
                          {Salon?.logo == "" ? (
                            <>
                              <img
                                src={logo}
                                className="avatar-md1 rounded-circle img-thumbnail"
                                style={{ height: "100px", width: "100px" }}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={URLS.Base + Salon?.logo}
                                className="avatar-md1 rounded-circle img-thumbnail"
                                style={{ height: "100px", width: "100px" }}
                              />
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <ul className="list-unstyled mt-2 ">
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-user-circle text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Salon Name</h6>
                          <p className="text-muted fs-14 mb-0">{Salon?.name}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-3">
                        <i className="bx bx-phone text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Phone</h6>
                          <p className="text-muted fs-14 mb-0">
                            {Salon?.mobileNumber}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-mail-send text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Email</h6>
                          <p className="text-muted fs-14 mb-0">
                            {VendorEmail?.salonEmail == "" ||
                            VendorEmail?.salonEmail == null ? (
                              <>-</>
                            ) : (
                              <>{VendorEmail?.salonEmail}</>
                            )}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-handicap text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2"> Seats </h6>
                          <p className="text-muted fs-14 mb-0">
                            {Salon?.seats == "" ? (
                              <>{"-"}</>
                            ) : (
                              <>{Salon?.seats}</>
                            )}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-store-alt text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Address </h6>
                          <p className="text-muted fs-14 mb-0">
                            {Salon?.address == "" ? (
                              <>{"-"}</>
                            ) : (
                              <>{Salon?.address}</>
                            )}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-map-pin text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">City </h6>
                          <p className="text-muted fs-14 mb-0">
                            {Salon?.city == "" ? (
                              <>{"-"}</>
                            ) : (
                              <>{Salon?.city}</>
                            )}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h5 className="text-primary">User Details :-</h5>
                  <Col md={12}>
                    <div className="text-center">
                      <div className="avatar-md1 profile-user-wid  mt-1">
                        <span className="ml-5">
                          {User?.profilePic == "" ? (
                            <>
                              <img
                                src={logo}
                                className="avatar-md1 rounded-circle img-thumbnail"
                                style={{ height: "100px", width: "100px" }}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={URLS.Base + User?.profilePic}
                                className="avatar-md1 rounded-circle img-thumbnail"
                                style={{ height: "100px", width: "100px" }}
                              />
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <ul className="list-unstyled mt-2 ">
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-id-card text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">User Id</h6>
                          <p className="text-muted fs-14 mb-0">{User?.userUniqueId}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-3">
                        <i className="bx bx-user-circle text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">User Name</h6>
                          <p className="text-muted fs-14 mb-0">{User?.name}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-3">
                        <i className="bx bx-phone text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Phone</h6>
                          <p className="text-muted fs-14 mb-0">
                            {User?.mobileNumber}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-mail-send text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Email</h6>
                          <p className="text-muted fs-14 mb-0">{User?.email}</p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-male-sign text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Gender </h6>
                          <p className="text-muted fs-14 text-break mb-0">
                            {User?.gender == "" ? (
                              <>{"-"}</>
                            ) : (
                              <>{User?.gender}</>
                            )}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-wallet-alt text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Wallet </h6>
                          <p className="text-muted fs-14 text-break mb-0">
                            {User?.wallet}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-map text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Address </h6>
                          <p className="text-muted fs-14 mb-0">
                            {User?.address == "" ? (
                              <>{"-"}</>
                            ) : (
                              <>{User?.address}</>
                            )}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h5 className="text-primary pb-2">Payments :-</h5>
                  <div className="table-rep-plugin table-responsive">
                    <Table hover className="table table-bordered">
                      <tbody>
                        <tr className="text-center">
                          <th>Booking Id</th>
                          <td>{Payments?.bookingId}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Payment Id</th>
                          <td>{Payments?.paymentId}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Transaction Id</th>
                          <td>
                            {Payments?.transactionId == "" ||
                            Payments?.transactionId == null ? (
                              <>-</>
                            ) : (
                              <>{Payments?.transactionId}</>
                            )}
                          </td>
                        </tr>
                        <tr className="text-center">
                          <th>Service Amount</th>
                          <td>{Payments?.subtotal}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Gocut Commission</th>
                          <td>{Payments?.gocutCharges}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Discount</th>
                          <td>- {Payments?.gocutDiscount}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Gst(Free)</th>
                          <td>{Payments?.tax}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Wallet Spent</th>
                          <td>- {Payments?.walletAmount}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Platform Fee</th>
                          <td>+ {Payments?.transactionCharges}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Total Amount</th>
                          <td>{Payments?.totalAmount}</td>
                        </tr>
                        <tr className="text-center">
                          <th>PayType</th>
                          <td>{Payments?.paymentMethod}</td>
                        </tr>
                        {/* adding charges row */}
                     
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h5 className="text-primary">Booking Details:-</h5>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4 ">
                      <thead>
                        <tr className="text-center">
                          <th>S.No</th>
                          <th>Slot Id</th>
                          <th>Date / Time </th>
                          <th>Chair No</th>
                          <th>Service Type</th>
                          <th>Service Name</th>
                          <th>Service Amount</th>
                          <th>Cancelled By</th>
                          <th>Cancelled Name</th>
                          <th>Reason</th>
                          <th>Bill</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Booking.map((datas, keys) => (
                          <tr className="text-center" key={keys}>
                            <td>{keys + 1}</td>
                            <td>{datas?.cSlotId}</td>
                            <td>
                              {datas?.date} / {datas?.time}
                            </td>
                            <td>{datas?.chairNumber}</td>
                            <td>{datas?.type}</td>
                            <td>
                              {datas?.vendorserviceName?.map((data, key) => (
                                <li key={key}>
                                  {data} <br></br>
                                </li>
                              ))}
                            </td>
                            <td>
                              {datas?.servicePrice?.map((data, key) => (
                                <li key={key}>
                                  {data} <br></br>
                                </li>
                              ))}
                            </td>
                              <td>{datas?.cancelledBytype}</td>
                              <td>{datas?.cancelledByName}</td>
                              <td>{datas?.cancellReason}</td>
                            <td>
                              <Button
                                outline
                                className="btn-sm"
                                color="warning"
                                onClick={() => {
                                  getinfo(datas)
                                }}
                              >
                                <div className="d-flex">
                                  <i className="bx bxs-file px-1"></i>
                                  <small> View </small>
                                </div>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={info}
            toggle={() => {
              infos()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                View Invoice :-
              </h5>
              <button
                onClick={() => {
                  setinfo(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="pt-2">
                <iframe
                  src={URLS.Base + Information}
                  height="400px"
                  width="100%"
                ></iframe>
              </p>
            </div>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TestsView
