import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Button, Table } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import { URLS } from "../../Url";

function Ventures() {
  const [Actin, setActin] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [detailsState, setDetailsState] = useState({}); 

  const history = useHistory();

  const gets = localStorage.getItem("authUser");
  const data = JSON.parse(gets);
  const token = data.token;
  const [listPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    GetUsers();
    datass();
    // Load details state from localStorage
    const savedDetailsState = JSON.parse(localStorage.getItem('detailsState')) || {};
    setDetailsState(savedDetailsState);
  }, []);

  useEffect(() => {
    localStorage.setItem('detailsState', JSON.stringify(detailsState));
  }, [detailsState]);

  const GetUsers = () => {
    axios
      .post(URLS.GetVendorList, {}, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => {
        setActin(res.data.data);
        setIsLoading(false);
      });
  };

  const pagesVisited = pageNumber * listPerPage;
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(Actin.length / listPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const OrderId1 = data => {
    sessionStorage.setItem("UserId", data._id);
    history.push("/ViewVendor");
  };

  const datass = () => {
    const location = sessionStorage.getItem("tost");
    if (location !== "") {
      toast(location);
      sessionStorage.clear();
    } else {
      sessionStorage.clear();
    }
  };

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to InActive?");
    if (confirmBox === true) {
      Delete(data);
    }
  };

  const Delete = data => {
    const gs = { action: "inactive", vendorId: data._id };
    axios
      .put(URLS.BlockVendors, gs, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => {
        if (res.status === 200) {
          toast(res.data.message);
          GetUsers();
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message);
        }
      });
  };

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you really want to Active?");
    if (confirmBox === true) {
      Delete1(data);
    }
  };

  const Delete1 = data => {
    const gs = { action: "active", vendorId: data._id };
    axios
      .put(URLS.BlockVendors, gs, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => {
        if (res.status === 200) {
          toast(res.data.message);
          GetUsers();
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message);
        }
      });
  };

  const [Searchs, setSearchs] = useState([]);

  const Search = e => {
    let myUser = { ...Searchs };
    myUser[e.target.name] = e.target.value;
    setSearchs(myUser);
    axios
      .post(URLS.GetVendorListSearch + `${e.target.value}`, {}, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => {
        setActin(res.data.data);
      });
  };

  const OrderId = data => {
    sessionStorage.setItem("UserId", data._id);
    history.push("/EditVendor");
  };

  // Adding the new function name is handleDetails 
  const handleDetails = data => {
    const isAccess = !detailsState[data._id]; 
    setDetailsState(prevState => ({
      ...prevState,
      [data._id]: isAccess,
    }));

    axios
      .post(
        'https://api.gocut.in/v1/api/admin/vendors/ApproveBeautyAccess',
        { vendorId: data._id },
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      )
      .then(response => {
        if (response.status === 200) {
          toast(isAccess ? "Access removed successfully." : "Access granted successfully.");
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message);
        }
      });
  };
  

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="GoCut" breadcrumbItem="Vendors List" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Link to="/AddVendors">
                        <Button color="primary">
                          New Vendor <i className="bx bx-plus-circle"></i>
                        </Button>
                      </Link>
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          value={Searchs.search}
                          onChange={Search}
                          className="form-control"
                          placeholder="Search.."
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin table-responsive">
                    <Table hover className="table table-bordered mb-4 mt-5">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Salon Name</th>
                          <th>Vendor Id</th>
                          <th>Vendor Name</th>
                          <th>Mobile Number</th>
                          <th>Seating </th>
                          <th>Address</th>
                          <th>Category Name</th>
                          <th>Online Status</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.salonName}</td>
                            <td className="text-primary" type="button"  onClick={() => {
                                  OrderId1(data);
                                }}>{data.userUniqueId}</td>
                            <td>{data.name}</td>
                            <td>{data.mobileNumber}</td>
                            <td>{data.salonSeating}</td>
                            <td>{data.salonAddress?.slice(0, 20)}...</td>
                            <td>{data.salonCategoryName}</td>
                            <td>
                              {data.salonStoreStatus === true ? (
                                <span className="badge bg-success">Online</span>
                              ) : (
                                <span className="badge bg-danger">Offline</span>
                              )}
                            </td>
                            <td>
                              <span className="badge bg-success ">{data.status}</span>
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  OrderId(data);
                                }}
                                size="sm"
                                className="m-1"
                                color="success"
                              >
                                <div className="d-flex">
                                  <i className="bx bx-edit px-1"></i> <small>Edit</small>
                                </div>
                              </Button>
                              <Button
                                onClick={() => {
                                  OrderId1(data);
                                }}
                                size="sm"
                                className="m-1"
                                color="info"
                              >
                                <div className="d-flex">
                                  <i className="fas fa-eye px-1"></i> <small> View </small>
                                </div>
                              </Button>
                              {/* Adding the one button for true or false */}
                              <Button
                                onClick={() => handleDetails(data)}
                                size="sm"
                                className={`m-1 ${detailsState[data._id] ? "btn-success" : "btn-danger"}`}
                              >
                                <div className="d-flex align-items-center">
                                  <i className={`fas ${detailsState[data._id] ? "fa-check-circle" : "fa-info-circle"} px-1`}></i>
                                  <small>{detailsState[data._id] ? "Access" : "Remove"}</small>
                                </div>
                              </Button>

                              {data.status === "active" ? (
                                <Button
                                  onClick={() => {
                                    manageDelete(data);
                                  }}
                                  size="sm"
                                  className="m-1"
                                  color="danger"
                                >
                                  <div className="d-flex">
                                    <i className="fas fa-user-lock px-1"></i> <small> INActive </small>
                                  </div>
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    manageDelete1(data);
                                  }}
                                  size="sm"
                                  className="m-1"
                                  outline
                                  color="success"
                                >
                                  <div className="d-flex">
                                    <i className="fas fa-user-check"></i> <small> Active </small>
                                  </div>
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="d-flex mt-3 mb-1" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Ventures;
