const Url = "https://api.gocut.in/"

export const URLS = {
  Base: Url,

  //Dashboard
  GetDashboard: Url + "v1/api/admin/getadmindashboard",

  //Dashboard
  GetAllVendorDashboard: Url + "v1/api/admin/getvendordashboard",

  //Settings
  GetSetting: Url + "v1/api/admin/policies/getpolicies",
  UpdatePrivacypolicy: Url + "v1/api/admin/policies/updateprivacypolicy",
  UpdateTermsandcondition:
    Url + "v1/api/admin/policies/updatetermsandcondition",
  UpdateRefundPolicy: Url + "v1/api/admin/policies/updaterefundPolicy",

  //Contactus
  GetContactUs: Url + "v1/api/admin/setting/getall",
  UpdateGetContactUs: Url + "v1/api/admin/setting/updateContactUs",

  //profile
  getProfile: Url + "v1/api/admin/auth/getProfile",
  UpdateProfile: Url + "v1/api/admin/auth/editprofile",
  UpdateImage: Url + "v1/api/admin/auth/updateprofileImg",
  ChangePass: Url + "v1/api/admin/auth/changepassword",
  forget: Url + "v1/api/admin/auth/generateotp",
  OTP: Url + "v1/api/admin/auth/compareOtp",
  Resetpass: Url + "v1/api/admin/auth/resetpassword",

  //Department
  AddDepartment: Url + "v1/api/admin/department/create",
  GetDepartment: Url + "v1/api/admin/department/getall",
  GetDepartmentSearch: Url + "v1/api/admin/department/getall?searchQuery=",
  UpdateDepartment: Url + "v1/api/admin/department/edit",
  InActiveDepartment: Url + "v1/api/admin/department/delete",

  //Role
  AddRole: Url + "v1/api/admin/roles/addrole",
  GetRole: Url + "v1/api/admin/roles/getAll",
  GetOneRole: Url + "v1/api/admin/roles/getrole",
  EditRole: Url + "v1/api/admin/roles/editrole/",
  DeleteRole: Url + "v1/api/admin/roles/deleterole/",

  //Staff
  AddStaff: Url + "v1/api/admin/staff/create",
  GetStaff: Url + "v1/api/admin/staff/getAll",
  GetStaffSearch: Url + "v1/api/admin/staff/getAll?searchQuery=",
  EditStaff: Url + "v1/api/admin/staff/edit/",
  DeleteStaff: Url + "v1/api/admin/staff/delete/",
  GetIdRole: Url + "v1/api/admin/staff/getdropdown",
  GetIdStaff: Url + "v1/api/admin/staff/getstaff",

  //Banners
  AddBanners: Url + "v1/api/admin/banner/create",
  GetBanners: Url + "v1/api/admin/banner/getall",
  GetBannersSearch: Url + "v1/api/admin/banner/getall?searchQuery=",
  UpdateBanners: Url + "v1/api/admin/banner/edit/",
  InActiveBanners: Url + "v1/api/admin/banner/delete/",

  //RecommendedBanner
  AddRecommendedBanner: Url + "v1/api/admin/recomendedbanner/create",
  GetRecommendedBanner: Url + "v1/api/admin/recomendedbanner/getall",
  GetRecommendedBannerSearch:
    Url + "v1/api/admin/recomendedbanner/getall?searchQuery=",
  UpdateRecommendedBanner: Url + "v1/api/admin/recomendedbanner/edit/",
  InActiveRecommendedBanner: Url + "v1/api/admin/recomendedbanner/delete/",

  //UserPlans
  AddUserPlans: Url + "v1/api/admin/plans/create",
  GetUserPlans: Url + "v1/api/admin/plans/getall",
  GetUserPlansSearch: Url + "v1/api/admin/plans/getall?searchQuery=",
  UpdateUserPlans: Url + "v1/api/admin/plans/edit/",
  InActiveUserPlans: Url + "v1/api/admin/plans/delete/",

  //VendorPlans
  AddVendorPlans: Url + "v1/api/admin/plans/create",
  GetVendorPlans: Url + "v1/api/admin/plans/getall",
  GetVendorPlansSearch: Url + "v1/api/admin/plans/getall?searchQuery=",
  UpdateVendorPlans: Url + "v1/api/admin/plans/edit/",
  InActiveVendorPlans: Url + "v1/api/admin/plans/delete/",
  GetPlanId: Url + "v1/api/admin/vendors/subscribed/planbyid",
  GetPlanIdSearch: Url + "v1/api/admin/vendors/subscribed/planbyid?searchQuery=",
  getVendorplanWiseList: Url + "v1/api/admin/vendors/subscribed/vendorlist",
  getVendorplanWiseListSearch:
    Url + "v1/api/admin/vendors/subscribed/vendorlist?searchQuery=",

  //Users
  GetUser: Url + "v1/api/admin/getuserlist",
  GetUserSearch: Url + "v1/api/admin/getuserlist?searchQuery=",
  GetUserID: Url + "v1/api/admin/user/updatestatus/",

  //Vendors
  GetVendors: Url + "v1/api/admin/vendors/getvendorlist",
  GetVendorsSearch: Url + "v1/api/admin/vendors/getvendorlist?searchQuery=",
  GetVendorsID: Url + "v1/api/admin/vendors/getvendorlistbyid",

  //Category
  AddCategory: Url + "v1/api/admin/category/create",
  GetCategory: Url + "v1/api/admin/category/getall",
  GetCategorySearch: Url + "v1/api/admin/category/getall?searchQuery=",
  UpdateCategory: Url + "v1/api/admin/category/edit/",
  InActiveCategory: Url + "v1/api/admin/category/delete/",

  //SubCategory
  AddSubCategory: Url + "v1/api/admin/subcategory/create",
  GetSubCategory: Url + "v1/api/admin/subcategory/getall",
  GetSubCategorySearch: Url + "v1/api/admin/subcategory/getall?searchQuery=",
  UpdateSubCategory: Url + "v1/api/admin/subcategory/edit/",
  InActiveSubCategory: Url + "v1/api/admin/subcategory/delete/",

  //services
  Addservices: Url + "v1/api/admin/services/create",
  Getservices: Url + "v1/api/admin/services/getall",
  GetservicesSearch: Url + "v1/api/admin/services/getall?searchQuery=",
  Updateservices: Url + "v1/api/admin/services/edit/",
  InActiveservices: Url + "v1/api/admin/services/delete/",

  //servicesCategory
  AddservicesCategory: Url + "v1/api/admin/servicecategory/addserivecategory",
  GetservicesCategory:
    Url + "v1/api/admin/servicecategory/getallserivecategory",
  GetservicesCategorySearch:
    Url + "v1/api/admin/servicecategory/getallserivecategory?searchQuery=",
  UpdateservicesCategory:
    Url + "v1/api/admin/servicecategory/editserivecategory/",
  InActiveservicesCategory:
    Url + "v1/api/admin/servicecategory/deleteserivecategory/",

  //faqs
  AddFaqs: Url + "v1/api/admin/faq/addfaq",
  GetFaqs: Url + "v1/api/admin/faq/getAll",
  GetFaqsSearch: Url + "v1/api/admin/faq/getAll?searchQuery=",
  UpdateFaqs: Url + "v1/api/admin/faq/editfaq",
  InActiveFaqs: Url + "v1/api/admin/faq/deletefaq",

  //PendingVendor
  PendingGetVendor: Url + "v1/api/admin/vendors/pendingvendorlist",
  PendingGetOneVendor: Url + "v1/api/admin/vendors/getpendingvendorbyid",
  PendingGetVendorSearch:
    Url + "v1/api/admin/vendors/pendingvendorlist?searchQuery=",
  UpdateVendors: Url + "v1/api/admin/vendors/isverified/updateisverified",

  //VendorList
  AddVendor: Url + "v1/api/admin/vendors/addvendor",
  GetVendorList: Url + "v1/api/admin/vendors/getvendorlist",
  GetVendorIdList: Url + "v1/api/admin/vendors/getvendorlistbyid",
  GetVendorListSearch: Url + "v1/api/admin/vendors/getvendorlist?searchQuery=",
  BlockVendors: Url + "v1/api/admin/vendors/blokorunblock",
  GetVendorIdLists: Url + "v1/api/admin/vendors/getsinglevendor",
  UpdateVendorIdLists: Url + "v1/api/admin/vendors/updatevendor",

  //VendorCamaigns
  GetVendorCamaigns: Url + "v1/api/admin/vendors/getvendorcampaigns",
  GetVendorCamaignsSearch:
    Url + "v1/api/admin/vendors/getvendorcampaigns?searchQuery=",
  VendorsCamaignsId: Url + "v1/api/admin/vendors/getvendorcampaignbyid",

  //VendorWalletRequest
  VendorWalletRequest: Url + "v1/api/admin/getvendorwalletpayment",
  VendorWalletRequestSearch:
    Url + "v1/api/admin/getvendorwalletpayment?searchQuery=",
  UpdateVendorWalletRequest: Url + "v1/api/admin/vendor/updatewalletrequest/",

  //UserWalletRequest
  UserWalletRequest: Url + "v1/api/admin/getuserwalletpayment",
  UserWalletRequestSearch:
    Url + "v1/api/admin/getuserwalletpayment?searchQuery=",
  // UpdateUserWalletRequest: Url + "v1/api/admin/userwallet/updatestatus/",

  //VendorSupport
  GetVendorSupport: Url + "v1/api/admin/getvendorqueries",
  GetVendorSupportSearch: Url + "v1/api/admin/getvendorqueries?searchQuery=",
  UpdateVendorSupport: Url + "v1/api/admin/vendorsupport/updatestatus/",

  //UserSupport
  GetUserSupport: Url + "v1/api/admin/getuserqueries",
  GetUserSupportSearch: Url + "v1/api/admin/getuserqueries?searchQuery=",
  UpdateUserSupport: Url + "v1/api/admin/usersupport/updatestatus/",
  GetOneUser: Url + "v1/api/admin/getuserlistbyid",

  //Coupons
  AddCoupon: Url + "v1/api/admin/coupon/addcpn",
  GetCoupon: Url + "v1/api/admin/coupon/getAll",
  GetCouponSearch: Url + "v1/api/admin/coupon/getAll?searchQuery=",
  UpdateCoupon: Url + "v1/api/admin/coupon/editcpn",
  InActiveCoupon: Url + "v1/api/admin/coupon/deletecoupon",

  //Notification
  AddNotifications: Url + "v1/api/admin/notification/addnotification",
  GetNotifications: Url + "v1/api/admin/notification/getallnotifications",
  GetNotificationsSearch:
    Url + "v1/api/admin/notification/getallnotifications?searchQuery=",
  DeleteNotifications: Url + "v1/api/admin/notification/deletenotification/",

  //BookingsSection
  GetAllBookings: Url + "v1/api/admin/adminbooking/getallbookings",
  GetAllBookingsSearch:
    Url + "v1/api/admin/adminbooking/getallbookings?searchQuery=",
  GetAllPendingBookings: Url + "v1/api/admin/adminbooking/getpendingbookings",
  GetAllPendingBookingsSearch:
    Url + "v1/api/admin/adminbooking/getpendingbookings?searchQuery=",
  GetAllCompletedBookings:
    Url + "v1/api/admin/adminbooking/getcompletedbookings",
  GetAllCompletedBookingsSearch:
    Url + "v1/api/admin/adminbooking/getcompletedbookings?searchQuery=",
  GetAllCancelledBookings:
    Url + "v1/api/admin/adminbooking/getcancelledbookings",
  GetAllCancelledBookingsSearch:
    Url + "v1/api/admin/adminbooking/getcancelledbookings?searchQuery=",
  GetAllSheduleBookings: Url + "v1/api/admin/booking/getshiftedbookings",
  GetAllSheduleBookingsSearch:
    Url + "v1/api/admin/booking/getshiftedbookings?searchQuery=",
  // GetOneBookingsId: Url + "v1/api/admin/adminbooking/getbookingbyid",
  GetOneBookingsId: Url + "v1/api/admin/adminbooking/gettheBookingbyid",

  //Charges
  Getcharges: Url + "v1/api/admin/setting/getall",
  Updatecharges: Url + "v1/api/admin/setting/updateContactUs",

  //Payments
  GetAllPayments: Url + "v1/api/admin/getallpaymenthistory",
  GetAllPaymentsSearch: Url + "v1/api/admin/getallpaymenthistory?searchQuery=",

  //Reports
  AllBookingsReports: Url + "v1/api/admin/report/allbookingreport",
  AllBookingsReportsSearch:
    Url + "v1/api/admin/report/allbookingreport?searchQuery=",
  VendorListReports: Url + "v1/api/admin/report/vendorlistreport",
  VendorListReportsSearch:
    Url + "v1/api/admin/report/vendorlistreport?searchQuery=",
  VendorSubscriptionReports: Url + "v1/api/admin/report/vendorsubscriberreport",
  VendorSubscriptionReportsSearch:
    Url + "v1/api/admin/report/vendorsubscriberreport?searchQuery=",
  VendorWalletReports: Url + "v1/api/admin/report/vendorwalletreport",
  VendorWalletReportsSearch:
    Url + "v1/api/admin/report/vendorwalletreport?searchQuery=",
  UserListReports: Url + "v1/api/admin/report/userlistreport",
  UserListReportsSearch:
    Url + "v1/api/admin/report/userlistreport?searchQuery=",
  UserWalletListReports: Url + "v1/api/admin/report/userwalletreport",
  UserWalletListReportsSearch:
    Url + "v1/api/admin/report/userwalletreport?searchQuery=",
  UserSubscriptionReports: Url + "v1/api/admin/adminbooking/gettheBookingbyid",
  UserSubscriptionReportsSearch:
    Url + "v1/api/admin/adminbooking/gettheBookingbyid?searchQuery=",
}
