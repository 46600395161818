import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard">
                <i className="fas fa-home"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-wallet-alt"></i>
                <span>{props.t("Employee Managment")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/Departments">{props.t("Departments")}</Link>
                </li>
                <li>
                  <Link to="/RolesPremissions">
                    {props.t("Roles & Premissions")}
                  </Link>
                </li>
                <li>
                  <Link to="/Staff">{props.t("Staff")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-collection"></i>
                <span>{props.t("Services")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ServiceCategory">
                    {props.t("Service Category")}
                  </Link>
                </li>{" "}
                <li>
                  <Link to="/SubService">
                    {props.t("Service Sub Category")}
                  </Link>
                </li>
                <li>
                  <Link to="/Services">{props.t("Services")}</Link>
                </li>
                {/* <li>
                  <Link to="/Category">{props.t("Category")}</Link>
                </li> */}
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-business"></i>
                <span>{props.t("Vendors")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/AllVendorsDashboard">
                    {props.t("All Vendor Dashboard")}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/PendingVendorsList">
                    {props.t("Pending Vendor")}
                  </Link>
                </li> */}
                <li>
                  <Link to="/Vendors">{props.t("Vendor List")}</Link>
                </li>
                <li>
                  <Link to="/InActiveVendor">
                    {props.t("Inactive Vendor List")}
                  </Link>
                </li>
                <li>
                  <Link to="/VendorPlanWiseList">
                    {props.t("Vendor Subscriptions")}
                  </Link>
                </li>
                <li>
                  <Link to="/Campaigns">{props.t("Vendor Campaigns")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-id-card"></i>
                <span>{props.t("User")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/Users">{props.t("User list")}</Link>
                </li>
                <li>
                  <Link to="/InActiveUsers">{props.t("InActive User")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-bookmarks"></i>
                <span>{props.t("Booking Managment")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/Booking">{props.t("All Bookings")}</Link>
                </li>
                <li>
                  <Link to="/Pendingbookings">
                    {props.t("Pending Bookings")}
                  </Link>
                </li>
                <li>
                  <Link to="/CompletedBookings">
                    {props.t("Completed Bookings")}
                  </Link>
                </li>
                <li>
                  <Link to="/CanclledBookings">
                    {props.t("Cancelled Booking List")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-receipt"></i>
                <span>{props.t("Payments Managment")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/Payments">{props.t("Booking Payments")}</Link>
                </li>
                <li>
                  <Link to="/UserWallet">{props.t("User Wallet")}</Link>
                </li>
                <li>
                  <Link to="/Wallet">{props.t("Vendor Wallet")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/VendorPlan">
                <i className="bx bxs-map-alt"></i>
                <span>{props.t("Vendor Plans")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-images"></i>
                <span>{props.t("Banners")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/SubBanners">{props.t("User Banners")}</Link>
                </li>
                <li>
                  <Link to="/Banners">{props.t("Vendor Banners")}</Link>
                </li>
                <li>
                  <Link to="/RecommendedBanners">
                    {props.t("Recommended Salon Banners")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-file-doc"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/BookingReport">{props.t("Bookings Report")}</Link>
                </li>
                <li>
                  <Link to="/VendorWiseReport">
                    {props.t("Vendors List Report")}
                  </Link>
                </li>
                <li>
                  <Link to="/VendorSubscription">
                    {props.t("Vendors Subscription Report")}
                  </Link>
                </li>
                <li>
                  <Link to="/VendorWalletReport">
                    {props.t("Vendors Wallet Report")}
                  </Link>
                </li>
                <li>
                  <Link to="/UserWiseReport">
                    {props.t("Users List Report")}
                  </Link>
                </li>
                <li>
                  <Link to="/UserWalletReport">
                    {props.t("Users Wallet Report")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-support"></i>
                <span>{props.t("Technical Support")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/VendorSupport">{props.t("Vendor Support")}</Link>
                </li>
                <li>
                  <Link to="/UserSupport">{props.t("User Support")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/Coupones">
                <i className="fas fa-ticket-alt"></i>
                <span>{props.t("Coupons")}</span>
              </Link>
            </li>

            <li>
              <Link to="/Notifications">
                <i className="fa fa-bell"></i>
                <span>{props.t("Notifications")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="fas fa-cogs"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ContactUs">{props.t("Contact Us")}</Link>
                </li>
                <li>
                  <Link to="/Faqs">{props.t(" FAQ'S")}</Link>
                </li>
                <li>
                  <Link to="/Charges">{props.t("Charges")}</Link>
                </li>
                <li>
                  <Link to="/PrivicyPolicy">{props.t(" Privacy Policy")}</Link>
                </li>
                <li>
                  <Link to="/RefundPolicy">{props.t(" Refund Policy")}</Link>
                </li>
                <li>
                  <Link to="/Termsconditions">
                    {props.t("Terms & Conditions")}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
