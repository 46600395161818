import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Table,
  Button,
  Form,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import { useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"

function Ventures() {
  const history = useHistory()

  const Bookingsid = data => {
    sessionStorage.setItem("BookingId", data.orderId)
    history.push("/BookingView")
  }

  const [Bookings, setBookings] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetUsers()
    GetactiveVendors()
  }, [])

  const GetUsers = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllPendingBookings,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setBookings(res.data.data)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Bookings.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Bookings.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetAllPendingBookingsSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setBookings(res.data.data)
      })
  }

  const [Vendors, setVendors] = useState([])

  const GetactiveVendors = () => {
    var token = datas

    axios
      .post(
        URLS.GetVendors,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setVendors(res.data.data)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    fromDate: new Date().toISOString().split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
    salonId: "",
  })

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const getfilter = e => {
    e.preventDefault()
    GetOrderFiliter()
  }

  const GetOrderFiliter = () => {
    var token = datas
    const data = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      salonId: filters.salonId,
    }
    axios
      .post(URLS.GetAllPendingBookings, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setBookings(res.data.data)
        hidefilter()
        setfilters({
          fromDate: new Date().toISOString().split("T")[0],
          toDate: new Date().toISOString().split("T")[0],
          salonId: "",
        })
      })
  }

  const hidefilter = () => setfilter(false)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="GoCut" breadcrumbItem="Pending Booking list" />
          {filter ? (
            <>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      getfilter(e)
                    }}
                  >
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-declaration-input10">
                            From Date <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="date"
                            required
                            className="form-control"
                            id="basicpill-Declaration-input10"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                            name="fromDate"
                            value={filters.fromDate}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-declaration-input10">
                            To Date <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="date"
                            required
                            className="form-control"
                            id="basicpill-Declaration-input10"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                            name="toDate"
                            value={filters.toDate}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Salon Name<span className="text-danger">*</span>
                          </Label>
                          <select
                            className="form-select"
                            required
                            value={filters.salonId}
                            name="salonId"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            <option value=""> Select </option>
                            {Vendors.map((data, key) => {
                              return (
                                <option key={key} value={data.salonObjectId}>
                                  {data.salonName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mt-4">
                          <Button type="submit" className="m-1" color="info">
                            <i className="fas fa-check-circle"></i> search
                          </Button>
                          <Button
                            onClick={hidefilter}
                            className="m-1"
                            color="danger"
                          >
                            <i className="fas fa-times-circle"></i> Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      {" "}
                      <Button
                        className="m-1"
                        onClick={() => {
                          setfilter(!filter)
                        }}
                        color="info"
                      >
                        <i className="fas fa-filter"></i> Filter
                      </Button>
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          value={Searchs.search}
                          onChange={Search}
                          className="form-control"
                          placeholder="Search.."
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4 ">
                      <thead>
                        <tr className="text-center">
                          <th>S.No</th>
                          <th>Salon Name </th>
                          <th>Booking Id</th>
                          <th>User Id</th>
                          <th>User Name</th>
                          <th>Contact No</th>
                          <th>Services Type</th>
                          <th>Services Details</th>
                          <th>Date/Time</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 5 + key + 6}</td>
                            <td>{data.salonName}</td>
                            <td>{data.bookingId}</td>
                            <td>{data.userUniqueId}</td>
                            <td>{data.userName}</td>
                            <td>{data.userPhone}</td>
                            <td>{data.type}</td>
                            <td>
                              {data?.serviceName?.map((datas, keys) => (
                                <p key={keys}>
                                  {keys + 1}){datas}<br></br>
                                </p>
                              ))}
                            </td>
                            <td>
                              {data.date?.slice(0, 10)}/{data.time}
                            </td>
                            <td>
                              <span className="badge bg-warning">
                                {data.status}
                              </span>
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  Bookingsid(data)
                                }}
                                size="sm"
                                className="m-1"
                                color="info"
                              >
                                <div className="d-flex">
                                  <i className="fas fa-eye px-1"></i>
                                  <small> View </small>
                                </div>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
