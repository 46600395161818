import React, { useEffect, useState } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { CSVLink } from "react-csv"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { URLS } from "../../Url"

function PaymentReport() {
  const [Agent, setAgent] = useState([])

  useEffect(() => {
    AgentReport()
  }, [])

  const [userInCsv, setuserInCsv] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const AgentReport = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllPayments,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAgent(res.data.data)
        setuserInCsv(res.data.paymentExcel)
      })
  }

  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Agent.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Agent.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    fromDate: "",
    toDate: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetOrderFiliter()
  }

  const GetOrderFiliter = () => {
    var token = datas
    const data = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    }
    axios
      .post(
        URLS.GetAllPayments,
        data ,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAgent(res.data.data)
        setuserInCsv(res.data.paymentExcel)
        hidefilter()
        setfilters("")
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const csvReport = {
    filename: "Payment Report",
    data: userInCsv,
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A2"
    const orientation = "portrait"

    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const headers = [
      [
        "S.No",
        "Salon Name ",
        "Booking Id",
        "User Name",
        "User Phone",
        "Services Details",
        "Transtation Id",
        "Payments / Date",
        "Amount",
        "Payment Status",
      ],
    ]

    const data = Agent.map((elt, i) => [
      i + 1,
      elt.salonName,
      elt.bookingId,
      elt.userName,
      elt.userContact,
      elt.serviceName,
      elt.paymentId,
      elt.logCreatedDate.slice(0, 10),
      elt.subtotal,
      elt.paymentStatus,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("report.pdf")
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)
    var token = datas
    axios
      .post(
        URLS.GetAllPaymentsSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAgent(res.data.data)
        setuserInCsv(res.data.paymentExcel)
      })
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="GoCut" breadcrumbItem="Booking Payments" />
            {filter ? (
              <>
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        getfilter(e)
                      }}
                    >
                      <Row>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              From Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="fromDate"
                              value={filters.fromDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              To Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="toDate"
                              value={filters.toDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mt-4">
                            <Button type="submit" className="m-1" color="info">
                              <i className="fas fa-check-circle"></i> search
                            </Button>
                            <Button
                              onClick={hidefilter}
                              className="m-1"
                              color="danger"
                            >
                              <i className="fas fa-times-circle"></i> Cancel
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </>
            ) : (
              ""
            )}
            <Card>
              <CardBody>
                <div>
                  <div className="table-responsive">
                    <div className="col-sm-12">
                      <div style={{ float: "right" }}>
                        <Row>
                          <Col>
                            <div style={{ float: "right" }}>
                              <CSVLink {...csvReport}>
                                <button
                                  className="btn btn-success me-2"
                                  type="submit"
                                >
                                  <i className="fas fa-file-excel"></i> Excel
                                </button>
                              </CSVLink>
                              <Button
                                type="button"
                                className="btn btn-danger "
                                onClick={exportPDF}
                              >
                                <i className="fas fa-file-pdf"></i> Pdf
                              </Button>
                              <Button
                                className="m-1"
                                onClick={() => {
                                  setfilter(!filter)
                                }}
                                color="info"
                              >
                                <i className="fas fa-filter"></i> Filter
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="mt-3 ">
                      <div style={{ float: "left" }} className="mb-3">
                        <Input
                          type="search"
                          className="form-control"
                          placeholder="Search.."
                          value={search.search}
                          onChange={searchAll}
                          name="search"
                        />
                      </div>
                      <Table
                        id="empTable"
                        className="table table-bordered mb-3 mt-5"
                      >
                        <thead>
                          <tr className="text-center">
                            <th>S.No</th>
                            <th>Salon Name </th>
                            <th>Booking Id</th>
                            <th>User Id</th>
                            <th>User Name</th>
                            <th>User Phone</th>
                            <th>Service Type</th>
                            <th style={{ width: "300px" }}>Services Details</th>
                            <th>Transtation Id</th>
                            <th>Payment / Date </th>
                            <th>Amount</th>
                            <th>Payment Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr className="text-center" key={key}>
                              <td>{(pageNumber - 1) * 5 + key + 6}</td>
                              <td>{data.salonName}</td>
                              <td>{data.bookingId}</td>
                              <td>{data.userUniqueId}</td>
                              <td>{data.userName}</td>
                              <td>{data.userContact}</td>
                              <td>{data.bookingType}</td>
                              <td style={{ width: "300px" }}>
                                {data?.serviceName?.map((datas, keys) => (
                                  <p key={keys}>
                                    {keys + 1}) {datas} <br></br>
                                  </p>
                                ))}
                              </td>
                              <td>{data.paymentId}</td>
                              <td>{data.logCreatedDate.slice(0, 10)}</td>
                              <td>{data.totalAmount}</td>
                              <td>{data.paymentStatus}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className="mt-3" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default PaymentReport
